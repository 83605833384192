/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

function Seo({ title, ogTitle, description, ogDescription, ogImage, language, meta, appStoreBanner }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            ogTitle
            description
            ogDescription
            ogImage
            defaultLang
            appStoreBanner
          }
        }
      }
    `
  )

  const lang = language || site.siteMetadata?.defaultLang
  const configTitle = site.siteMetadata?.title
  const metaDescription = description || site.siteMetadata?.description
  const metaOgTitle = ogTitle || site.siteMetadata?.ogTitle
  const metaOgDescription = ogDescription || site.siteMetadata?.ogDescription
  const metaOgImage = ogImage || site.siteMetadata?.ogImage
  const metaAppStoreBanner = appStoreBanner || site.siteMetadata?.appStoreBanner

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? `${title} | ${configTitle}` : configTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaOgTitle,
        },
        {
          property: 'og:description',
          content: metaOgDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: metaOgImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: metaOgTitle,
        },
        {
          name: 'twitter:description',
          content: metaOgDescription,
        },
        {
          name: `apple-itunes-app`,
          content: metaAppStoreBanner,
        },
        
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: null,
  meta: [],
  description: ``,
  ogDescription: ``,
  ogTitle: ``,
  ogImage: ``,
  appStoreBanner: ``,
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  ogTitle: PropTypes.string,
  description: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default Seo
